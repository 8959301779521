@import "bulma/css/bulma.css";
@import url("https://fonts.googleapis.com/css2?family=Passion+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300&display=swap");

.bebas {
  font-family: "Bebas Neue", cursive;
}

.roboto {
  font-family: "Roboto Condensed", sans-serif;
}

.logo {
  font-size: 2.4rem;
}

.dark-gray {
  color: #343434;
}

.thin-text {
  font-weight: 300;
}

.lg-font {
  font-size: 1.2rem;
}

.xl-font {
  font-size: 5rem;
  line-height: 4.5rem;
}

.xxl-font {
  font-size: 8rem;
  line-height: 7.5rem;
}

.App {
  text-align: center;
}

.subtitle {
  color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.sticky {
  position: fixed;
  width: 100%;
  padding: 1rem 0;
  z-index: 99;
}

.navbar {
  background: transparent;
}

.navbar-item {
  color: white;
  text-decoration: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.icon {
  font-size: 1.2rem;
  margin-left: 1rem;
  color: white;
}

.hero {
  width: 100%;
  height: 100vh;
  background: url("./images/new-york-city-with-empire-state-building.webp");
  background-size: cover;
  background-position: bottom;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 0;
}

.weather {
  margin-top: 3rem;
  color: white;
  z-index: 1;
}

.hero .overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 0;
}

.hero-content {
  z-index: 2;
  position: relative;
}

.hero-content h1 {
  color: white;
}

.btn-primary {
  border-radius: 30px !important;
  padding: 0.5rem 2rem !important;
}

.btn-success {
  border-radius: 30px !important;
  padding: 0.5rem 2rem !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

#work {
  width: 100%;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(228, 228, 228, 1) 100%
  );
  height: 60vh;
  padding: 3rem 0.5rem;
}

.button-port {
  position: relative;
  height: 100%;
  display: inline-block;
  overflow: hidden;
}

.overlay-port {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background: rgba(0, 0, 0, 0.85);
  display: flex;
  flex-direction: column;
  padding: 1rem;
  align-items: center;
  justify-content: center;
  transform: translateX(-340px);
  transition: transform 0.7s;
}

.button-port:hover .overlay-port {
  transform: none;
}

#work .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

#work .container .row {
  width: 100%;
}

.react-multi-carousel-item {
  padding: 0.7rem;
}

.portfolio {
  box-shadow: 1px 3px 15px rgba(0, 0, 0, 0.2);
}

#about-me {
  width: 100%;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(228, 228, 228, 1) 100%
  );
  height: 100vh;
}

#about-me .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.image-round {
  overflow: hidden;
  border-radius: 50%;
  box-shadow: 1px 3px 15px rgba(0, 0, 0, 0.2);
}

#about-me .container .row {
  display: flex;
  align-items: center;
  justify-content: center;
}

#skills {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: relative;
  background-color: #343434;
  background: url(./images/cleveland.webp);
  background-size: cover;
}

#skills .row {
  position: relative;
  z-index: 1;
}

#skills .overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 0;
}

.icons-1 {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
}

.icons-2 {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
}

.icon-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.icon-skill {
  width: 80px;
  height: auto;
}

.flags {
  width: 80px;
  height: auto;
}

#languages {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: relative;
  background-color: #343434;
  background: url(./images/languages-cleveland.webp);
  background-size: cover;
  background-position: center;
}

#languages .row {
  position: relative;
  z-index: 1;
}

#languages .overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 0;
}

#contact {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  background: url(./images/phone.jpg);
  background-size: cover;
}

#contact .container-fluid,
#contact .container-fluid .row {
  height: 100%;
}

form {
  width: 80%;
  display: flex;
  flex-direction: column;
}

.form-control {
  background-color: transparent !important;
  border: none !important;
  border-bottom: 1px solid #ffffff !important;
  border-radius: 0px !important;
  color: white !important;
}

.form-control:focus {
  box-shadow: none !important;
}

.form-control::placeholder {
  color: white !important;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: white !important;
}

.form-control::-ms-input-placeholder {
  color: white !important;
}

.textarea {
  background-color: transparent;
  border-radius: 0px !important;
  color: white !important;
}

.phone {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(250, 178, 60, 0.8);
  backdrop-filter: blur(5px);
}

.form-wrapper {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(38, 38, 38, 0.8);
  backdrop-filter: blur(5px);
}

.contact-wrapper {
  width: auto;
  text-align: left;
}

.sticky {
  transition: background 0.7s;
}

.scrolled {
  background: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(5px);
}

.navbar-burger {
  color: white;
}

.material-symbols-sharp {
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (min-width: 1024px) {
  .navbar-toggler {
    display: none;
  }

  .navbar-item {
    transition: background-color 1s;
    border-radius: 6px;
    margin: "0 0.5rem";
  }

  .navbar-item.active {
    background-color: white;

    color: black;
    border-radius: 6px;
  }
}

@media only screen and (max-width: 1023px) {
  .mobile-nav {
    position: sticky;
    background: #333;
    z-index: 200;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translateY(-100vh);
    transition: 1s;
  }

  .mobile-nav .navbar-item {
    width: 100%;
  }

  .navbar-menu {
    background-color: #333;
    transition: transform 1s;
    transform: translateX(-400px);
  }

  .navbar-menu.open {
    display: block;
    width: 100%;
    transform: none;
  }

  .mobile-nav.clicked {
    transform: translateY(0);
  }

  .xxl-font {
    font-size: 2.5rem;
    line-height: 2rem;
  }

  .subtitle {
    font-size: 1rem;
    padding: 0px 0.7rem;
  }

  .xl-font {
    font-size: 2rem;
    line-height: 1.8rem;
  }

  #about-me {
    height: auto;
    padding: 3rem 1rem;
  }

  .image-round {
    margin-bottom: 2rem;
  }

  .icons-1 {
    grid-template-columns: 33% 33% 33%;
  }

  .icons-2 {
    grid-template-columns: 33% 33% 33%;
  }

  .icon-wrapper {
    margin: 0.5rem;
    padding: 1rem;
  }

  #languages {
    height: auto;
    padding: 3rem 1rem;
  }

  #contact {
    height: auto;
  }

  #work {
    height: auto;
  }

  .phone {
    padding: 3rem 1rem;
  }

  .form-wrapper {
    padding: 3rem 1rem;
  }

  .navbar-brand {
    width: 100%;
    justify-content: space-between;
  }

  .navbar-collapse {
    display: flex;
  }

  .buttons {
    justify-content: center;
  }

  #skills {
    height: auto;
    padding: 3rem 1rem;
  }
}
